<!--
 * @Description: 明细详情
 * @Author: zhang zhen
 * @Date: 2023-07-27 17:11:05
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-04-04 10:38:11
 * @FilePath: /page-sass/src/views/quotePriceManagement/modules/detailsInfo.vue
-->
<template>
  <div>
    <a-descriptions class="tag_1 last-card" :column="3" v-if="formModel == 2">
      <template v-for="item in baseInfo.formItemDtoList">
        <template v-if="item.itemConfig.componentType == 'SPLIT'">
          <a-descriptions-item></a-descriptions-item>
          <a-descriptions-item></a-descriptions-item>
          <a-descriptions-item label="" class="desc-split" :span="3">
            <a-divider orientation="left">
              <div class="split-title">{{ item.itemConfig.componentLabel }}</div>
            </a-divider>
          </a-descriptions-item>
        </template>
        <a-descriptions-item :label="item.itemConfig.componentLabel" v-else>
          {{ (item.itemConfig.componentType == 'TEXTAREA' || item.itemConfig.componentType == 'TEXT' || item.itemConfig.componentType == 'DATE_TIME'
            || item.itemConfig.componentType == 'DATE' ? item.itemValue : item.itemValueDictText) || '-'
          }}
        </a-descriptions-item>
      </template>
    </a-descriptions>
    <a-descriptions class="tag_2" v-show="showFile">
      <template v-slot:title>
        <div class="desc-title">
          <span class="title">附件</span>
        </div>
        <div class="card-view">
          <div class="card-view-item" v-for="i in baseInfo.fileList" :key="i.name">
            <div class="cover" v-viewer>
              <img :src="item.url" alt="" class="cover" v-if="i.isImage" />
              <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
            </div>
            <div class="option-area">
              <img src="~@/assets/view.png" alt="" title="预览" @click="show(i.url)" />
              <div class="split-line"></div>
              <img src="~@/assets/dowload.png" alt="" title="下载" />
            </div>
            <div class="info-card">
              <div class="sub_title">{{ i.fileName }}</div>
              <!-- <div class="labels">文件大小: -K</div>
              <div class="labels">上传日期: -</div> -->
            </div>
          </div>
        </div>
      </template>
    </a-descriptions>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)
export default {
  name: 'detailsInfo',
  props: {
    baseInfo: {
      type: Object,
      default: () => ({
        fileList: [],
        formItemDtoList: []
      }),
    },
    showFile: {
      type: Boolean,
      default: true
    },
    formModel: {
      type: [String, Number],
      default: '2'
    }
  },
  data() {
    return {}
  },
  methods: {
    show(url) {
      this.$viewerApi({
        images: [url],
      })
    },
  },
}
</script>

<style lang="less" scoped>
.desc-title {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 12px;
  margin-bottom: 15px;

  span.title {
    font-size: 14px;
    color: #131212;
  }
}

::v-deep .ant-descriptions-item-label {
  color: #595959;
}

::v-deep .ant-descriptions-item-content {
  color: #131212;
}

.ant-descriptions {
  background-color: #f7f8fa;
  padding: 16px 25px 0;

  &.tag_1 {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &.tag_2 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.card-view {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px 25px;

  &-item {
    width: calc((100% - 50px) / 3);
    height: 144px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px dashed #e8e9eb;
    padding: 15px;
    display: flex;
    position: relative;

    .cover {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        &+.option-area {
          opacity: 1;
          z-index: 10;
        }
      }
    }

    .option-area {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.65);
      position: absolute;
      left: 15px;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      z-index: -1;

      &:hover {
        opacity: 1;
        z-index: 10;
      }

      img {
        height: 16px;
        cursor: pointer;
      }

      .split-line {
        width: 1px;
        height: 14px;
        background-color: #fff;
        margin: 0 15px;
      }
    }
  }

  .info-card {
    flex: 1 0 0;
    min-width: 0;
    .sub_title {
      font-size: 14px;
      font-weight: 400;
      color: #131212;
      line-height: 22px;
      margin-bottom: 8px;
      .text-ellipsis-multi(2);
    }

    div.labels {
      font-size: 12px;
      color: #8d8e99;
      line-height: 20px;
      font-weight: 400;
    }
  }
}
</style>

