<!--
 * @Description: 报价详情
 * @Author: zhang zhen
 * @Date: 2023-07-27 15:33:45
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-20 17:44:36
 * @FilePath: /page-sass/src/views/quotePriceManagement/quotePriceDetails.vue
-->
<template>
  <div class="RequirementConfirmation">
    <div class="header">
      <div class="header-title">{{ baseInfo.purchaseTitle }}</div>
    </div>
    <div class="RequirementConfirmation-content">
      <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
        <h2 class="RequirementConfirmation-title"><span class="title">基本信息</span></h2>
        <a-descriptions :column="2" bordered style="margin-bottom: 25px;">
          <a-descriptions-item label="供应商参与数量">
            {{ baseInfo.participantCount || '-' }}
          </a-descriptions-item>

          <a-descriptions-item label="需求类型">
            {{ baseInfo.purchaseTypeDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="报价截止日期">
            {{ baseInfo.quotationDeadline || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="付款方式">
            {{ baseInfo.paymentMethodDictName || '-' }}
          </a-descriptions-item>
        </a-descriptions>
        <h2 class="RequirementConfirmation-title"><span class="title">采购商信息</span></h2>
        <a-descriptions :column="2" bordered style="margin-bottom: 25px;">
          <a-descriptions-item label="采购商名称" :span="2">
            {{ basicInfo.businessName || '个人' }}
          </a-descriptions-item>
          <a-descriptions-item label="产品类别">
            {{ basicInfo.productCategoryDictName || '-' }}
          </a-descriptions-item>

          <a-descriptions-item label="年包材需求总额">
            {{ basicInfo.packagingCostTotalDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="单次最小下单量">
            {{ basicInfo.minOrderCountDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="设计要求">
            {{ basicInfo.designDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="打样测试">
            {{ basicInfo.sampleTestDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="认证要求">
            {{ basicInfo.authMethodDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="其他服务">
            {{ basicInfo.otherServiceDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="下单周期">
            {{ basicInfo.orderCycleDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="要货方式">
            {{ basicInfo.purchasingMethodDictName || '-' }}
          </a-descriptions-item>
          <a-descriptions-item label="供货区域">
            {{ basicInfo.providerAreaDictName || '-' }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <!-- 供应商 -->
      <template>
        <div class="RequirementConfirmation-content-item" style="margin-top: 15px">
          <h2 class="RequirementConfirmation-title"><span class="title">详细要求</span></h2>
          <!-- v-if="formModel == 1" -->
          <template v-if="formModel == 1">
            <splitLine label="瓦楞纸包装" />
            <a-descriptions :column="2" bordered>
              <a-descriptions-item label="包装用途">
                {{ baseInfo.wrapTypeDictName || '-' }}
              </a-descriptions-item>

              <a-descriptions-item label="瓦楞箱型">
                {{ baseInfo.purchaseTypeDictName || '-' }}
              </a-descriptions-item>
            </a-descriptions>
            <splitLine label="上传文件" />
            <div class="excel-box">
              <div class="top-box">
                <img class="excel-icon" src="~@/assets/excel.png" alt="" />
                <!-- <span class="fileName">{{ excelName }}</span> -->
                <a @click="handleDownLoadFile(baseInfo.templateFile)">下载</a>
              </div>
            </div>
            <splitLine label="附件" />
            <div class="files-list">
              <div class="files-list-item" v-for="item in fileList" :key="item.name">
                <div class="cover">
                  <img :src="item.url" alt="" class="cover" v-if="item.isImage" />
                  <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
                </div>
                <div class="option-area">
                  <img src="~@/assets/view.png" alt="" title="预览" @click="handleDownLoadFile(item.url)" />
                  <div class="split-line"></div>
                  <img src="~@/assets/dowload.png" alt="" title="下载" @click="handleDownLoadFile(item.url)" />
                </div>
                <div class="info-card inside">
                  <div class="sub_title">{{ item.fileName }}</div>
                  <!-- <div class="labels">文件大小: -K</div>
                            <div class="labels">上传日期: -</div> -->
                </div>
              </div>
            </div>

          </template>
          <template v-else>
            <!-- <a-button type="link" class="edit-btn" @click="handleEdit('CompleteInformation')"
              :disabled="!purchaseItem.length || infoStatus == '2'" v-if="isMine == 1 && !isOut"><img
                src="~@/assets/edit.png" /><span>编辑</span></a-button> -->
            <a-tabs type="card">
              <a-tab-pane key="1" :tab="tabTitleList[index] ? tabTitleList[index] : `散件${index + 1}`"
                v-for="(i, index) in purchaseItem" :key="index">
                <RequirementInfoView :formItemDtoList="i.formInfo" />
                <splitLine label="附件" v-show="i.fileList && i.fileList.length" />
                <div class="files-list">
                  <div class="files-list-item" v-for="item in i.fileList" :key="item.name">
                    <div class="cover">
                      <img :src="item.url" alt="" class="cover" v-if="item.isImage" />
                      <img src="~@/assets/normalFile.jpg" alt="" class="cover" v-else />
                    </div>
                    <div class="option-area">
                      <img src="~@/assets/view.png" alt="" title="预览" @click="handleDownLoadFile(item.url)" />
                      <div class="split-line"></div>
                      <img src="~@/assets/dowload.png" alt="" title="下载" @click="handleDownLoadFile(item.url)" />
                    </div>
                    <div class="info-card inside">
                      <div class="sub_title">{{ item.fileName }}</div>
                      <!-- <div class="labels">文件大小: -K</div>
                            <div class="labels">上传日期: -</div> -->
                    </div>
                  </div>
                </div>
                <splitLine label="报价单" iconPath="/last-quote.png" v-if="!isView" />
                <div class="quote-table" v-if="!isView">
                  <a-table :columns="columns" :pagination="false" :data-source="i.dataSource" bordered>
                    <template slot="prePrice" slot-scope="text, record, index">{{ text || '由供应商报价' }}</template>
                    <template slot="price" slot-scope="text, record, index">
                      <a-input v-model="record.price" style="width: 100%;" placeholder="报价单价（元）"
                        @input="handleChangeInput($event, record)" v-show="record.isEdit"
                        @keyup.enter.native="handleEditRow(record, false)" />
                      <div v-show="!record.isEdit" @click="handleEditRow(record, true)">{{ text }}</div>
                    </template>
                    <template slot="total" slot-scope="text, record, index">
                      {{ multiply((record.num || 0), (record.price || 0)) }}
                    </template>
                    <template slot="footer" slot-scope="currentPageData">
                      <div class="footer-info-bar">
                        <span class="title">合计（元）</span>
                        <div class="total"> {{ multiply((currentPageData[0].num || 0), (currentPageData[0].price || 0))
                          }}</div>
                      </div>
                    </template>
                  </a-table>
                </div>
              </a-tab-pane>
            </a-tabs>
          </template>
        </div>
      </template>
    </div>
    <div class="action-button-area" v-if="!isView">
      <a-button type="primary" @click="handleQuote" :loading="loading">报价</a-button>
    </div>
    <h2 class="RequirementConfirmation-title" style="margin-top: 15px;"><span class="title">历史报价信息</span></h2>
    <!-- quoteList -->
    <EmptyArea v-if="!quoteList.length" description="暂无报价信息" />
    <template v-for="(item, index) in quoteList">
      <splitLine :label="`${item.quoteDesc}`" iconPath="/quotePrice.png" />
      <QuotePriceTable :class="quoteList.length - 1 != index && 'less-info'" :dataSource="item.quoteHistoryItemVos"
        :total="item.allTotal" />
    </template>
    <div class="action-button-area"  v-if="isView">
      <a-button @click="handleBack">返回</a-button>
    </div>
  </div>
</template>

<script>
import { postAction, postQueryAction, getAction } from '@/api/manage'
import DetailsInfo from './modules/detailsInfo.vue'
import { mapGetters } from 'vuex'
import ExcelUploader from '@/views/PublishingRequirements/modules/uploadFIle.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import RequirementInfoView from '@/components/plugins/RequirementInfoView.vue'
import QuotePriceTable from '@/components/plugins/quotePriceTable.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'

export default {
  name: 'quotePriceDetails',
  components: {
    DetailsInfo,
    ExcelUploader,
    JDictSelect,
    splitLine,
    RequirementInfoView,
    QuotePriceTable,
    EmptyArea
  },
  data() {
    return {
      showStep: true,
      purchaseId: null,
      baseInfo: {},
      basicInfo: {},
      purchaseItem: [],
      loading: false,
      quoteList: [],
      fileList: [],
      formInfo: {},
      form: {}, // 其他信息字段
      layoutInfo: {
        wrapperCol: { span: 12 },
        labelCol: { span: 12 },
      },
      columns: [
        {
          title: '散件所需数量',
          dataIndex: 'num',
          width: 266
        },
        {
          title: '采购商-预算单价（元）',
          dataIndex: 'prePrice',
          scopedSlots: { customRender: 'prePrice' },
        },
        {
          title: '报价单价（元）',
          dataIndex: 'price',
          width: 307,
          scopedSlots: { customRender: 'price' },
        },
        {
          title: '报价合计（元）',
          dataIndex: 'total',
          width: 177,
          scopedSlots: { customRender: 'total' },
        },
      ],
      formModel: 2,
      showFile: true,
      quoteType: '1',
      quoteId: null,
      tabTitleList: [],
      isView: false
    }
  },
  created() {
    const { id, quoteSource, quoteId, isView } = this.$route.query
    if (id) {
      this.purchaseId = id
      quoteId && (this.quoteId = quoteId);
      this.handleLoadInfo()
      this.quoteType = quoteSource || '1'
      this.isView = !!isView
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),

    handleBack() {
      this.$router.go(-1)
    },
    multiply(a, b) {
      const precision = this.getPrecision(a) + this.getPrecision(b);
      const multiplier = Math.pow(10, precision);
      return (Math.round(a * multiplier) * Math.round(b * multiplier)) / (multiplier * multiplier);
    },
    getPrecision(num) {
      const str = String(num);
      const decimalIndex = str.indexOf('.');
      return decimalIndex === -1 ? 0 : str.length - decimalIndex - 1;
    },
    handleChangeModel({ fileUrl, fileId }) {
      this.formInfo.file = fileUrl
    },
    handleEditRow(data, edit = true) {
      this.$set(data, 'isEdit', edit)
    },
    handleChangeInput(e, item) {
          // 获取输入的原始值  
            let inputValue = e.target.value;  
          
          // 替换非数字字符（只保留数字和一个小数点）  
          inputValue = inputValue.replace(/[^\d.]/g, '').replace(/(\..*?)\..*/g, '$1');  

          // 如果存在小数点，确保小数点后最多四位  
          if (inputValue.includes('.')) {  
            inputValue = inputValue.replace(/(\.\d{4})./g, '$1');  
          }  

          // 如果需要禁止负数（可选）  
          if (parseFloat(inputValue) < 0) {  
            inputValue = inputValue.replace('-', ''); // 移除负号  
            // 或者你可以选择设置inputValue为0或某个默认值  
          }  
          item.price = inputValue;
      this.$forceUpdate();
    },
    handleLoadInfo() {
      postAction(`/v2/purchases/showPurList/${this.purchaseId}`).then((res) => {
        const { success, data, message } = res
        if (success) {
          const { itemList } = data
          !itemList || itemList.length == 0 && (this.formModel = 1)
          postAction('/quote/history', {
            businessId: this.setUserInfo().businessId,
            purchaseId: this.purchaseId,
            status: 0
          }).then(res => {
            const { success, data } = res;
            if (success) {
              this.quoteList = data && data.reverse() || [];
            }
          })
          postQueryAction('/business/queryByBusinessId', {
            businessId: data.businessId
          }).then(res => {
            const { success, data } = res
            if (success) {
              const { capacity, supplierPurchasePlan = {}, tradeIdentity = {}, businessName } = data;
              this.basicInfo = Object.assign({ businessName }, tradeIdentity == 0 ? supplierPurchasePlan : capacity);
            }
          })

          if (data.uploadFile) {
            let fileList = data.uploadFile.split(',')
            fileList.map((i) => {
              let fileName = i.includes('/images/') ? i.split('/images/')[1] : i.split('/file/')[1]
              this.fileList.push({
                fileName,
                url: i,
                isImage: fileName.includes('.jpg') || fileName.includes('.png') || fileName.includes('.jpeg'),
              })
            })
          }
          this.priceBudget = 0
          this.purchaseItem = []
          let dataSource = []

          itemList.map(i => {
            const { wrapTypeDictName } = i;
            this.tabTitleList.push(`${data.purchaseTypeDictName || ''} - ${wrapTypeDictName || ''}`)
            // 计算合计金额
            let num = 0, price = 0;
            let targetNumItem = i.itemForms.find(item => item.fieldFlag == 'num')
            let targetPriceItem = i.itemForms.find(item => item.fieldFlag == 'price')
            console.log(targetNumItem, targetPriceItem, i.itemForms)
            if (targetNumItem && targetPriceItem) {
              num = targetNumItem.filedValue || 0
              price = targetPriceItem.filedValue || 0
              dataSource = [
                {
                  prePrice: price,
                  num,
                  isEdit: true,
                }
              ]
            }
            this.priceBudget += Number(num) * Number(price);
            // 上传图片回显示
            let baseUploadItem = i.itemForms.find(item => item.componentType == 'FILE'), fileList = [];
            this.showFile = !!baseUploadItem // 显示文件上传
            if (baseUploadItem && baseUploadItem.filedValue) {
              let fileArray = baseUploadItem.filedValue.split(',')
              fileArray.map((i) => {
                let fileName = i.includes('/images/') ? i.split('/images/')[1] : i.split('/file/')[1]
                fileList.push({
                  fileName,
                  url: i,
                  isImage: fileName.includes('.jpg') || fileName.includes('.png') || fileName.includes('.jpeg'),
                })
              })
            }

            let formItemDtoList = i.itemForms.filter(item => item.componentType != 'FILE')
            this.purchaseItem.push({
              purchaseItemId: formItemDtoList[0].purchaseItemId,
              formInfo: {
                arr: formItemDtoList,
                wrapTypeDictName
              },
              formItemDtoList,
              fileList,
              dataSource
            })
          })
          this.baseInfo = data
          if (this.quoteId && this.quoteId != 'null') {
            getAction(`/quote/get/${this.quoteId}`).then(res => {
              const { success, data } = res;
              if (success) {
                const { quoteItems } = data;
                quoteItems.map(i => {
                  const { purchaseItemId, quotePrice } = i;
                  this.purchaseItem.map(item => {
                    if (item.purchaseItemId == purchaseItemId) {
                      item.dataSource[0]['price'] = Number(quotePrice);
                    }
                  })
                })
                this.$forceUpdate()
              }
            })
          }
        } else {
          this.$message.warning(message)
        }
      })
    },
    // 计算价格
    handleSumNum() {
      this.$forceUpdate()
      this.baseInfo.priceBudget = this.purchaseItem.reduce((prev, next) => {
        const { packagingQuantity, price } = next
        return prev += Number(price || 0) * Number(packagingQuantity || 0)
      }, 0)
    },
    handleDownLoadFile(url) {
      window.open(url)
    },
    handleChangeQuoteType() {
      console.log(122)
      this.form.freight = 0;
    },
    // 报价
    handleQuote() {
      if (this.formModel == 1) {
        if (!this.formInfo.file) return this.$message.warning('请上传文件')
        postAction('/quote/add-template', {
          purchaseId: this.purchaseId,
          file: this.formInfo.file
        }).then((res) => {
          const { success, message } = res
          if (success) {
            // this.$message.success(message)
            this.$router.push('/quotePriceManagement/quotePriceList')
          } else {
            this.$message.warning(message)
          }
        })
      } else {
        console.log(this.purchaseItem)
        let hasNoPrice = this.purchaseItem.some((i) => {
          const { dataSource } = i;
          const { price } = dataSource[0]
          return !price
        })
        if (hasNoPrice) return this.$message.warning('存在没有填写报价单价的散件， 请检查');

        let items = this.purchaseItem.map((i) => {
          const { budget, dataSource, purchaseItemId } = i
          const { price } = dataSource[0]
          return {
            price: price || budget,
            purchaseItemId
          }
        })
        let formData = {
          businessId: this.setUserInfo().businessId,
          freight: 0,
          items,
          purchaseId: this.purchaseId,
          quoteType: '',
          quoteSource: this.quoteType,
        }
        postAction('/quote/add', formData).then((res) => {
          const { success, message } = res
          if (success) {
            // this.$message.success(message)
            this.$router.push('/quotePriceManagement/quotePriceList')
          } else {
            this.$message.warning(message)
          }
        })
      }
    },
    // 取消
    handleCancel() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="less" scoped>
.RequirementConfirmation {
  padding: 24px 24px 40px;
  box-sizing: border-box;
  background: #fff;

  .header {
    &-title {
      color: #090b12;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 15px;

      .copy {
        font-size: 13px;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }

  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;

    &:before {
      background: #ff6e2d;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 14px;
      color: #8d8e99;
    }
  }

  &-content {
    &-item {
      width: 100%;
      background: #fff;
      position: relative;

      .edit-btn {
        position: absolute;
        top: 4px;
        right: -15px;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep .ant-form-item-label {
    line-height: 22px;

    label {
      color: #626161 !important;
    }
  }

  ::v-deep .ant-form-item-control {
    line-height: 22px;
    color: #131212;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subTitle {
    width: calc(100% - 40px);
    font-size: 15px;
    color: #ff6e2d;
    padding-bottom: 8px;
    border-bottom: 1px solid #efefef;
    margin: 0 auto 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag-title {
      padding: 2px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background: #fff2ea;
      border-radius: 2px;
    }

    img {
      width: 15px;
      margin-right: 4px;
    }

    span {
      line-height: 22px;
      font-size: 14px;
    }
  }

  .basicInfo-form {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 18px 0 4px;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &-item {
      padding: 0 20px;
    }
  }

  ::v-deep .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-btn-link[disabled] img {
    filter: grayscale(100%);
  }

  .price-title {
    width: 107px;
    margin-top: 8px;
  }

  .process-content {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;

    ::v-deep .ant-timeline-item-tail {
      border: 0.5px dashed #dcdcdc;
    }

    ::v-deep .ant-timeline-item-head {
      width: 5px;
      height: 5px;
      background: #d7d7db;
      border: none;
      left: 2px;
      top: 3px;
    }

    .content {
      padding-top: 5px;
      color: #000000;
      font-size: 14px;
      line-height: 20px;
    }

    .time {
      font-size: 14px;
      line-height: 20px;
      color: #8d8e99;
    }
  }
}

.ant-descriptions {
  // background-color: #f7f8fa;
  // padding: 16px 25px 0;
  // border-radius: 8px;

  &.tag_2 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

::v-deep .ant-descriptions-item-label {
  color: #595959;
}

::v-deep .ant-descriptions-item-content {
  color: #131212;
}

::v-deep .ant-radio-disabled+span {
  color: #262626;
}

.card-view {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px 25px;

  &-item {
    width: calc((100% - 50px) / 3);
    height: 144px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px dashed #e8e9eb;
    padding: 15px;
    display: flex;
    position: relative;

    .cover {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        &+.option-area {
          opacity: 1;
          z-index: 10;
        }
      }
    }

    .option-area {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.65);
      position: absolute;
      left: 15px;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      z-index: -1;

      &:hover {
        opacity: 1;
        z-index: 10;
      }

      img {
        height: 16px;
        cursor: pointer;
      }

      .split-line {
        width: 1px;
        height: 14px;
        background-color: #fff;
        margin: 0 15px;
      }
    }
  }

  .info-card {
    &.inside {
      flex: 1 0 0;
      min-width: 0;
    }

    .sub_title {
      font-size: 14px;
      font-weight: 400;
      color: #131212;
      line-height: 22px;
      margin-bottom: 8px;
      overflow: hidden;
      .text-ellipsis-multi(2);
    }

    div.labels {
      font-size: 12px;
      color: #8d8e99;
      line-height: 20px;
      font-weight: 400;
    }
  }
}

.desc-title {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 12px;
  margin-bottom: 15px;

  span.title {
    font-size: 14px;
    color: #131212;
  }
}

div.file-card {
  width: max-content;
  height: 64px;
  background: #fff;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  margin-bottom: 8px;

  &-title {
    margin-bottom: 15px;

    img.icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .fileName {
      color: #1d2129;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-size {
    font-weight: 400;
    color: #8d8e99;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    padding-left: 22px;
  }
}

.coverBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.last-label {
  width: 100%;
  height: 102px;
  background-image: url('~@/assets/longerBG.png');
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  .ant-form-item {
    margin-bottom: 0;
  }

  ::v-deep .ant-input {
    border-color: #ff9a57;
  }

  ::v-deep .ant-input-suffix {
    color: #131212;
  }

  .moneyIcon {
    position: absolute;
    bottom: 0;
    right: 19px;
  }
}

.numberInput {
  width: 320px;
  border-color: #ff7d2f;
  height: 38px;
  line-height: 38px;

  ::v-deep .ant-input-number-input {
    font-size: 18px;
    color: #131212;
    font-weight: 500;
  }
}

.btns {
  text-align: center;
}

.cover-box {
  margin-top: 15px;
  text-align: right;
}

.quote-table {
  padding: 0 15px;
  overflow-x: auto;

  .ant-table-wrapper {
    width: 1056px;
  }

  ::v-deep .ant-table-thead>tr>th {
    background: #F4F5F7;
  }

  ::v-deep .ant-table-footer {
    height: 48px;
    padding: 0;

    .footer-info-bar {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 16px;
      box-sizing: border-box;

      .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
      }

      .total {
        height: 100%;
        border-left: 1px solid#E8E9EB;
        width: 484px;
        line-height: 46px;
        padding-left: 16px;
        color: #FF6026;
        font-size: 16px;
      }
    }
  }
}

.action-button-area {
  margin-top: 60px;
  text-align: center;

  .ant-btn-primary {
    width: 106px;
    color: #fff;
    height: 38px;
    line-height: 36px;
    font-weight: 600;
  }
}

@import '~@/styles/form.less';

::v-deep .less-info {
  .ant-table {
    color: rgba(0, 0, 0, 0.35) !important;
  }

  .total {
    color: rgba(255, 96, 38, 0.65) !important;
  }
}
</style>
